<template>
    <div>
        <el-dialog :title="title" width="60%" :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
            <el-menu :default-active="tabIndex" mode="horizontal" @select="handleSelectTab">
                <el-menu-item index="0">文件预览</el-menu-item>
                <el-menu-item index="1">文件详情</el-menu-item>
                <el-menu-item index="3">版本历史</el-menu-item>
                <!--                <el-menu-item index="4" >操作权限</el-menu-item>-->
                <el-menu-item index="2">文档元属性</el-menu-item>
            </el-menu>
            <!--文件预览-->
            <div v-if="tabIndex == '0'" class="m_t1" v-loading="loading2">
                <div style="width: 100%;height:calc(100vh - 320px)">
                    <iframe v-if="!loading2"
                            :src='this.$pdfPreviewUrl() + "onlinePreview?url=" + this.$Base64.encode(fileDetailsForm.url.replaceAll("\\", "/")) + "&mediaFlag=1"'
                            frameborder="0" width="100%" height="100%"></iframe>
                </div>
                <!--图片-->
                <!-- <div v-if="fileType === 'image'">
                            <el-image style="width: 100%;" :src="fileDetailsForm.url" fit="contain"></el-image>
                        </div> -->
                <!--视频-->
                <!-- <div v-else-if="fileType === 'video'">
                            <video style="width: 100%;" controls :src=fileDetailsForm.url></video>
                        </div> -->
                <!--音频-->
                <!-- <div v-else-if="fileType === 'radio'">
                            <audio style="width: 100%;" controls :src="fileDetailsForm.url"></audio>
                        </div> -->
                <!--pdf-->
                <!-- <div v-else-if="fileType === 'pdf'">
                            <pdf :src="fileDetailsForm.url" :page="currentPage"
                                 @num-pages="pageCount=$event"
                                 @page-loaded="currentPage=$event"
                                 @loaded="loadPdfHandler">
                            </pdf>
                            <p class="text_center m_b1">
                                <span @click="changePdfPage(0)">
                                    <i class="el-icon-caret-left pointer" style="font-size: 20px"></i>
                                </span>
                                <span style="position: relative;bottom: 2px">{{currentPage}} / {{pageCount}}</span>
                                <span @click="changePdfPage(1)">
                                    <i class="el-icon-caret-right pointer" style="font-size: 20px"></i>
                                </span>
                            </p>
                        </div> -->
                <!--word-->
                <!-- <div v-else-if="fileType === 'word'">
                            <iframe :src='fileDetailsForm.url' width='100%' height='623px' frameborder='0'>This is an embedded
                                <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by
                                <a target='_blank' href='http://office.com/webapps'>Office Online</a>.
                            </iframe>
                        </div>
                        <el-empty v-else description="无法预览"></el-empty> -->
            </div>
            <!--文件详情-->
            <div v-if="tabIndex == '1'" class="m_t1">
                <el-form class="dialogForm" size="small" :model="fileDetailsForm" ref="fileDetailsForm"
                         label-width="120px"
                         disabled>
                    <el-form-item :label="item.recordName + '：'" :prop="item.fieldEnname"
                                  v-for="(item, index) in config"
                                  :key="index">
                        <el-input v-if="item.detailsShowType == '0'"
                                  v-model="fileDetailsForm[item.fieldEnname]"></el-input>
                        <el-select v-if="item.detailsShowType == '1' && item.fieldEnname == 'quanzongNo'"
                                   v-model="fileDetailsForm[item.fieldEnname]" style="width:100%" @change="manaList(0)"
                                   placeholder="请选择">
                            <el-option v-for="item in exhibitionList" :key="item.id" :label="item.exhibitionName"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-if="item.detailsShowType == '1' && item.fieldEnname == 'uploadTarget'"
                                   v-model="fileDetailsForm[item.fieldEnname]" style="width:100%" placeholder="请选择">
                            <el-option v-for="item in catalogueList" :key="item.id" :label="item.directoryName"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-if="item.detailsShowType == '1' && item.fieldEnname == 'fieldName8'"
                                   v-model="fileDetailsForm[item.fieldEnname]" style="width:100%" placeholder="请选择">
                            <el-option v-for="item in $dictUtils.getDictList('file_type')" :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                        <el-date-picker v-if="item.detailsShowType == '2'" v-model="fileDetailsForm[item.fieldEnname]"
                                        type="year"
                                        style="width:100%" placeholder="选择年度"></el-date-picker>
                        <el-date-picker v-if="item.detailsShowType == '3'" v-model="fileDetailsForm[item.fieldEnname]"
                                        type="datetime"
                                        style="width:100%" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <!--文档元属性-->
            <div v-if="tabIndex == '2'" class="m_t1">
                <el-form size="small" :model="fileDetailsForm" ref="fileDetailsForm" label-width="120px"
                         style="width:70%"
                         disabled>
                    <el-form-item label="文件大小：" prop="fileSize">
                        <el-input v-model="(fileDetailsForm.fileSize / 1024 / 1024).toFixed(2) + 'M'"></el-input>
                    </el-form-item>
                    <el-form-item label="文件格式：" prop="fileFormat">
                        <el-input v-model="fileDetailsForm.fileFormat"></el-input>
                    </el-form-item>
                    <el-form-item label="更新人：" prop="updateName">
                        <el-input v-model="fileDetailsForm.updateName"></el-input>
                    </el-form-item>
                    <el-form-item label="更新时间：" prop="updateDate">
                        <el-input v-model="fileDetailsForm.updateDate"></el-input>
                    </el-form-item>
                    <el-form-item label="更新人IP：" prop="updateIp">
                        <el-input v-model="fileDetailsForm.updateIp"></el-input>
                    </el-form-item>
                    <el-form-item label="创建人：" prop="createName">
                        <el-input v-model="fileDetailsForm.createName"></el-input>
                    </el-form-item>
                    <el-form-item label="创建时间：" prop="createDate">
                        <el-input v-model="fileDetailsForm.createDate"></el-input>
                    </el-form-item>
                    <el-form-item label="创建人IP：" prop="createIp">
                        <el-input v-model="fileDetailsForm.createIp"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <!--版本历史-->
            <div v-if="tabIndex == '3'" class="m_t1">
                <el-table :data="dataList" v-loading="loading" size="small" height="calc(100% - 130px)" class="table">
                    <el-table-column prop="version" label="版本号" sortable width="80">
                        <template slot-scope="scope" v-if="scope.row.moveFlag == 0">
                            {{ scope.row.version }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="updateName" label="更新人" show-overflow-tooltip/>
                    <el-table-column prop="updateDate" label="更新时间" width="150" sortable show-overflow-tooltip/>
                    <el-table-column prop="fileName" label="更新内容" show-overflow-tooltip width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.moveFlag == 0">{{ scope.row.fileName }}</span>
                            <span v-else>
                从{{ scope.row.exhibitionName }}的{{ scope.row.directoryName }}
                【移动至】{{ scope.row.oldExhibitionName }}的{{ scope.row.oldDirectoryName }}
              </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createIp" label="更新IP" show-overflow-tooltip/>
                    <el-table-column prop="mainVersion" label="是否主版本">
                        <template slot-scope="scope" v-if="scope.row.moveFlag == 0">
                            <el-tag :type="scope.row.mainVersion === 1 ? 'success' : 'danger'">{{ scope.row.mainVersion
                                === 1 ? '是' :
                                '否' }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="200">
                        <template slot-scope="scope">
                            <!--                            <el-button type="text" size="mini" @click="restoreRow(scope.row)" v-if="scope.row.moveFlag == 1">还原</el-button>-->
                            <!--                            <el-button type="text" size="mini" @click="setMain(scope.row)" v-if="scope.row.moveFlag == 0 && scope.row.mainVersion ==0">设为主版本</el-button>-->
                            <el-button type="text" size="mini" @click="downloadRow2(scope.row)"
                                       v-if="scope.row.moveFlag == 0">下载
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                               :current-page="pageNo"
                               :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
                               layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
            <!--操作权限-->
            <div v-if="tabIndex == '4'" class="m_t1">
                <el-form size="small" :model="authorizationForm" ref="authorizationForm" label-width="120px"
                         style="width:70%">
                    <el-form-item label="预览人员：" prop="preview">
                        <el-select v-model="authorizationForm.previewPersonnel" style="width:100%" placeholder="请选择">
                            <el-option v-for="item in previewList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="编辑人员：" prop="editorial">
                        <el-select v-model="authorizationForm.editorial" style="width:100%" multiple placeholder="请选择">
                            <el-option v-for="item in editorialList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="m_t1 text_center">
                <el-button size="small" @click="clickOpen">全屏预览</el-button>
                <el-button size="small" type="primary" @click="print">打印</el-button>
                <el-button size="small" @click="downloadRow(fileDetailsForm)">下载</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="visible = false">取消</el-button>
                <!--                <el-button size="small" v-if="type!=='recycle' && (tabIndex=='1' || tabIndex=='4')" type="primary" @click="save()" v-noMoreClick>保存</el-button>-->
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import pdf from "vue-pdf";

    export default {
        components: {pdf},
        data() {
            return {
                title: '',
                tabIndex: "0",
                visible: false,
                // // 预览
                // previewData: {
                fileType: 'file',
                //     // url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                //     // url: 'https://cms.cnc.blzstatic.cn/cms/gallery/4G8KGRWWG7FS1557732437703.mp4',
                //     // url: 'https://hnst.zyddbai.com/9688152ce5580a6cfbcc073fadfa7c39.pdf',
                //     url: 'http://downsc.chinaz.net/Files/DownLoad/sound1/201906/11582.mp3',
                // },
                currentPage: 0, // pdf文件页码
                pageCount: 0, // pdf文件总页数
                // 文件详情
                fileDetailsForm: {
                    id: "",
                    fileName: '',
                    quanzongNo: "",
                    uploadTarget: "",
                    pyear: '',
                    fieldName1: "",
                    pagenum: "",
                    writtenDate: "",
                    classification: "",
                    safekeepDate: "",
                    respoPerson: "",
                    remarks: '',
                    url: "",

                    fileSize: "",
                    fileFormat: "",
                    updateName: "",
                    updateDate: "",
                    updateIp: "",
                    createName: "",
                    createDate: "",
                    createIp: ""
                },
                fileDetailsForm2: {},
                // dataRule: {
                //     fileName: [{required: true, message: '文件名不能为空', trigger: 'blur'}],
                //     pyear: [{required: true, message: '年度不能为空', trigger: 'blur'}],
                //     writtenDate: [{required: true, message: '成文日期不能为空', trigger: 'blur'}],
                //     classification: [{required: true, message: '密级不能为空', trigger: 'blur'}],
                //     safekeepDate: [{required: true, message: '保管期限不能为空', trigger: 'blur'}],
                //     respoPerson: [{required: true, message: '责任者不能为空', trigger: 'blur'}],
                // },
                catalogueList: [],//目录
                exhibitionList: [],//展览

                // 版本历史
                dataList: [],
                loading: false,
                loading2: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                // 操作权限
                authorizationForm: {
                    previewPersonnel: '1',
                    editorial: ['1'],
                },
                previewList: [{
                    id: '1',
                    name: '项目组人员'
                }, {
                    id: '2',
                    name: '公司人员'
                }, {
                    id: '3',
                    name: '指定人员'
                }],
                editorialList: [{
                    id: '1',
                    name: '项目组人员'
                }, {
                    id: '2',
                    name: '公司人员'
                }, {
                    id: '3',
                    name: '指定人员'
                }],
                log: {
                    operModular: "5",//模块
                    operType: "8",//类型：移动
                    operTerm: "",//内容
                    operSystem: "1",//结果
                },
                fileVersionHistory: {
                    fileId: "",
                    primaryId: "",//移动前的所属展览id
                    secondarId: "",//移动前的所属目录id
                    moveFlag: 1,//移动
                    newPrimaryId: "",//移动后的所属展览id
                    newSecondarId: "",//移动后的所属目录id
                },
                filePath: "",
                id: ""
            }
        },
        mounted() {

        },
        methods: {
            clickOpen() {
                window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + this.$Base64.encode(this.fileDetailsForm.url.replaceAll('\\', '/')) + "&mediaFlag=0")
            },
            init(id) {
                this.tabIndex = '0';
                this.loading2 = true
                this.fileDetailsForm.url = ""
                this.querySysOriginalDescriptionAll(id);//详情页
                this.exhibitionInAll();//所有展览
                this.id = id
                this.reorganize(id)
            },
            //详情页配置
            querySysOriginalDescriptionAll(id) {
                let that = this;
                this.$axios(this.api.catalogue.querySysOriginalDescriptionAll, {
                    flag: 0,
                    onDetails: 0
                }, 'get').then(data => {
                    if (data) {
                        this.config = data
                        this.config.forEach(function (v) {
                            v.fieldEnname = that.toHump(v.fieldEnname)
                            that.$set(that.fileDetailsForm, v.fieldEnname, "")
                        })
                        this.fileCollectQueryById(id);
                    }
                })
            },
            toHump(name) {
                return name.replace(/\_(\w)/g, function (all, letter) {
                    return letter.toUpperCase();
                });
            },
            //文件信息
            fileCollectQueryById(id) {
                this.$axios(this.api.common.fileCollectQueryById, {
                    'id': id,
                    'flag': 0,
                    'onDetails': 0
                }, 'get').then(data => {
                    if (data.status) {
                        this.visible = true
                        this.title = data.data.fileName;
                        this.fileDetailsForm = this.recover(this.fileDetailsForm, data.data)
                        this.filePath = JSON.parse(JSON.stringify(this.fileDetailsForm.url))
                        // this.fileDetailsForm.url=this.$getUrl()+this.fileDetailsForm.url
                        this.addWatermark().then(data => {
                            if (data) {
                                // if(this.filePath.match(RegExp(/home/))){
                                //     this.fileDetailsForm.url = this.$getUrl()+data.substring(data.indexOf("/"),data.length)
                                // }else{
                                this.fileDetailsForm.url = data.substring(data.indexOf("\\"), data.length)
                                // }
                            } else {
                                // if(this.filePath.match(RegExp(/home/))){
                                //     this.fileDetailsForm.url= this.$getUrl()+this.fileDetailsForm.url.substring(this.fileDetailsForm.url.indexOf("/"),this.fileDetailsForm.url.length)
                                // }else{
                                this.fileDetailsForm.url = this.fileDetailsForm.url.substring(this.fileDetailsForm.url.indexOf("\\"), this.fileDetailsForm.url.length)
                                // }
                            }
                            this.loading2 = false
                        })
                        // this.fileDetailsForm.fileSize=(this.fileDetailsForm.fileSize/1024/1024).toFixed(2)
                        if (this.fileDetailsForm.quanzongNo == 0) {
                            this.fileDetailsForm.quanzongNo = ""
                        }
                        this.manaList();//根据展览id查询所有目录
                        //原来的文件对象，用于判断是否修改，记录日志
                        this.fileDetailsForm2 = JSON.parse(JSON.stringify(this.fileDetailsForm))
                        this.getFileSrc()
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            //展览
            exhibitionInAll() {
                this.$axios(this.api.common.queryExhibition2, {}, 'get').then(data => {
                    if (data.status) {
                        this.exhibitionList = data.data;
                    }
                })
            },
            //目录
            manaList(type) {
                if (type == 0) {
                    this.fileDetailsForm.uploadTarget = ''
                }
                this.$axios(this.api.mana.selectSysDirectory, {
                    "exhibitionId": this.fileDetailsForm.quanzongNo
                }, 'get').then(data => {
                    if (data.status) {
                        this.catalogueList = data.data;
                    }
                })
            },
            //版本信息
            reorganize(id) {
                this.$axios(this.api.common.versionReorganize, {
                    "fileId": id,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data.status) {
                        this.dataList = data.data.records;
                        let that = this;
                        this.total = parseInt(data.data.total)
                        this.dataList.forEach(function (v) {
                            if (v.url) {
                                that.$set(v, "filePath", v.url);
                                v.url = that.$globalUrl() + v.url.substring(v.url.indexOf("\\"), v.url.length)
                            }
                        })
                    }
                })
            },
            // 保存
            save() {
                if (this.tabIndex == '1') {
                    this.$refs['fileDetailsForm'].validate((valid) => {
                        if (valid) {
                            if (!this.$publicUtile.isEqual(this.fileDetailsForm, this.fileDetailsForm2)) {
                                // this.$publicUtile.getObjectValueEqual(this.fileDetailsForm,this.fileDetailsForm2)
                                this.$axios(this.api.common.fileCollectUpdateById, this.fileDetailsForm, 'put').then(data => {
                                    if (data.status) {
                                        this.$message.success(data.msg)
                                        this.$emit('refreshDataList')
                                        this.visible = false
                                    }
                                })
                            } else {
                                this.visible = false
                            }
                        }
                    })
                } else {
                    this.visible = false
                }

            },
            // pdf加载时
            loadPdfHandler(e) {
                this.currentPage = 1 // 加载的时候先加载第一页
            },
            // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
            changePdfPage(val) {
                if (val === 0 && this.currentPage > 1) {
                    this.currentPage--
                }
                if (val === 1 && this.currentPage < this.pageCount) {
                    this.currentPage++
                }
            },
            getFileSrc() {
                this.fileType = "file"
                let fileType = this.fileDetailsForm.fileFormat
                if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'bmp' || fileType === 'gif'
                    || fileType === 'PNG' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'BMP' || fileType === 'GIF') {
                    this.fileType = 'image'
                } else if (fileType === 'pdf' || fileType === 'PDF') {
                    this.fileType = 'pdf'
                } else if (fileType === 'doc' || fileType === 'docx') {
                    this.fileType = 'word'
                    // this.fileDetailsForm.url = 'https://view.officeapps.live.com/op/view.aspx?src=' + this.fileDetailsForm.url
                } else if (fileType === 'mp4' || fileType === 'm2v' || fileType === 'mkv') {
                    this.fileType = 'video'
                } else if (fileType === 'mp3' || fileType === 'wav' || fileType === 'wmv') {
                    this.fileType = 'radio'
                }
            },
            // tab切换
            handleSelectTab(index) {
                this.tabIndex = index;
            },
            // 还原
            restoreRow(row) {
                let fileCollect = {
                    id: row.fileId,
                    quanzongNo: row.primaryId,
                    uploadTarget: row.secondarId
                }
                this.log.operType = '2'
                this.$axios(this.api.common.fileCollectUpdateById, fileCollect, 'put').then(data => {
                    if (data.status) {
                        this.log.operSystem = '0'
                        this.$message.success(data.msg)
                        this.$emit('refreshDataList')
                        this.visible = false
                        this.batchInsert(row);
                    }
                    row.fileName = this.fileDetailsForm.fileName
                    // this.logSave2(row);
                })
            },
            batchInsert(row) {
                let that = this;
                let batchList = []
                that.fileVersionHistory.fileId = row.fileId
                that.fileVersionHistory.primaryId = this.fileDetailsForm.quanzongNo
                that.fileVersionHistory.secondarId = this.fileDetailsForm.uploadTarget
                that.fileVersionHistory.newPrimaryId = row.primaryId
                that.fileVersionHistory.newSecondarId = row.secondarId
                batchList.push(that.fileVersionHistory)
                this.$axios(this.api.common.FileVersionHistoryBatchInsert, batchList, 'post').then(data => {
                    // if(data.status){
                    // this.getFileList()
                    // }
                })
            },
            //设置主版本
            setMain(row) {
                let fileCollect = {
                    id: row.id,
                    mainVersion: 1,
                    fileId: this.fileDetailsForm.id,
                }
                this.$axios(this.api.common.FileVersionHistoryUpdateById, fileCollect, 'put').then(data => {
                    if (data.status) {
                        this.log.operSystem = '0'
                        this.$message.success(data.msg)
                        this.$emit('refreshDataList')
                        this.visible = false
                    }
                    // this.logSave2(row);
                })
            },
            //新增日志
            logSave2(row) {
                if (row) {
                    this.log.operTerm = row.fileName
                    this.$axios(this.api.common.logSave, this.log, 'post').then(data => {
                        // if(data.status){
                        // this.getFileList()
                        // }
                    })
                }
            },
            //打印
            print() {
                let log = {
                    operModular: "5",//模块
                    operType: "16",//类型
                    operTerm: this.fileDetailsForm.fileName,//内容
                    operSystem: "1",//结果:失败
                }
                if (this.fileType == 'pdf' || this.fileType == 'image') {
                    this.$print({
                        printable: this.fileDetailsForm.url,
                        type: this.fileType,
                        header: null,
                        targetStyles: ['*'],
                        style: "@page {margin:0 10mm}"
                    })
                    log.operSystem = "0"
                } else {
                    this.$message.error("仅支持图片和pdf格式文件！")
                }
            },
            addWatermark() {
                let watermark = JSON.parse(sessionStorage.getItem('userInfo')).name + " " + this.$publicUtile.formatDate(new Date())
                let sourceFile = JSON.parse(sessionStorage.getItem('userInfo')).id + this.filePath.substring(this.filePath.lastIndexOf("/"), this.filePath.length)
                return new Promise((resolve, reject) => {
                    if (this.fileDetailsForm.fileFormat == "pdf" || this.fileDetailsForm.fileFormat == "PDF") {
                        this.$axios("zyd-catalogue/pdf/addWatermark", {
                            "target": this.filePath,
                            "sourceFile": sourceFile,
                            "watermark": watermark
                        }, 'post').then(data => {
                            if (data.status) {
                                resolve(data.data)
                            } else {
                                resolve("")
                            }
                        })
                    } else {
                        resolve("")
                    }
                })
            },
            addWatermark2(url) {
                let watermark = JSON.parse(sessionStorage.getItem('userInfo')).name + " " + this.$publicUtile.formatDate(new Date())
                let sourceFile = JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("/"), url.length)
                // if(url.match(RegExp(/home/))){
                //     sourceFile = url.substring(0,(url.indexOf("/"))+1)+"home/fileTemporary/"+JSON.parse(sessionStorage.getItem('userInfo')).id+url.substring(url.lastIndexOf("/"),url.length)
                // }else{
                //     sourceFile =url.substring(0,(url.indexOf("\\"))+1)+"fileTemporary\\"+JSON.parse(sessionStorage.getItem('userInfo')).id+url.substring(url.lastIndexOf("\\"),url.length)
                // }
                return new Promise((resolve, reject) => {
                    this.$axios("zyd-catalogue/pdf/addWatermark", {
                        "target": url,
                        "sourceFile": sourceFile,
                        "watermark": watermark
                    }, 'post').then(data => {
                        if (data.status) {
                            resolve(sourceFile)
                        } else {
                            resolve("")
                        }
                    })
                })
            },
            // 下载
            downloadRow(row) {
                let log = {
                    operModular: "5",//模块
                    operType: "19",//类型
                    operTerm: row.fileName,//内容
                    operSystem: "1",//结果:失败
                }
                this.addWatermark(row.url).then(res => {
                    let url = ""
                    if (res) {
                        url = res
                    } else {
                        url = row.url;
                    }
                    const a = document.createElement('a')
                    // 这里是将url转成blob地址，
                    fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                        a.href = URL.createObjectURL(blob)
                        a.download = row.fileName + "." + row.fileFormat // 下载文件的名字
                        // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                        document.body.appendChild(a)
                        a.click()
                        log.operSystem = "0"
                    })
                })


            },
            downloadRow2(row) {
                let that = this;
                if (row.fileFormat == "pdf" || row.fileFormat == "PDF") {
                    that.addWatermark2(row.filePath).then(data => {
                        let url = ""
                        if (data) {
                            url = that.$globalUrl() + data.substring(data.indexOf("\\"), data.length)
                        } else {
                            url = row.url;  // 完整的url则直接使用
                            // this.fileDetailsForm.url= this.$getUrl()+this.fileDetailsForm.url.substring(this.fileDetailsForm.url.indexOf("\\"),this.fileDetailsForm.url.length)
                        }
                        let log = {
                            operModular: "5",//模块
                            operType: "19",//类型
                            operTerm: row.fileName,//内容
                            operSystem: "1",//结果:失败
                        }
                        const a = document.createElement('a')
                        //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                        // const url = v.url;  // 完整的url则直接使用
                        // 这里是将url转成blob地址，
                        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                            a.href = URL.createObjectURL(blob)
                            a.download = row.fileName + "." + row.fileFormat // 下载文件的名字
                            // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                            document.body.appendChild(a)
                            a.click()
                            log.operSystem = "0"
                        })

                    })
                } else {
                    this.downloadRow(row)
                }
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.reorganize(this.id);
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.reorganize(this.id);
            },
        }
    }
</script>

<style scoped></style>
